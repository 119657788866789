import React from "react"
import Layout from "../components/Layout"
import Heading from "../components/Heading"

export default class Index extends React.Component { 
  render() {
    return (
        <Layout location={this.props.location}>
            <div className="max-w-7xl mx-auto border-t border-b py-10 my-10 px-6">
                <Heading size="display">Display</Heading>
                <Heading size="h1">Heading 1</Heading>
                <Heading size="h2">Heading 2</Heading>
                <Heading size="h3">Heading 3</Heading>
                <Heading size="h4">Heading 4</Heading>
                <Heading size="h5">Heading 5</Heading>
                <Heading size="h6">Heading 6</Heading>
            </div>
            <div className="max-w-7xl mx-auto mb-10 px-6">
                <p className="font-poppins text-xl leading-tight font-light mb-4">Intro - Lorem ipsum dolor sit amet</p>
                <p className="font-poppins text-base leading-tight mb-4">Body - Lorem ipsum dolor sit amet</p>
                <p className="font-poppins text-base font-light leading-tight mb-4">Caption - Lorem ipsum dolor sit amet</p>
                <a className="font-poppins text-base font-medium uppercase leading-tight" href="#">Link text</a>
            </div>
        </Layout>
    )
  }
}
 